import {IResident} from "@source/api/models/residentModel";

export interface IGetNursingHomeResponse {
  name: string,
  address: string,
  phone: string,
  email: string,
  photo: string,
  _id: string,
  accessKeys: string[],
  organization: {
    description: string,
    name: string,
    owner: {
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
    },
    _id: string,
  },
  isEmployeeUploaded: boolean,
  isFamiliesUploaded: boolean,
  status: HomeStatus,
  lastEmployeeInvitation: string,
  lastFamiliesInvitation: string,
  endOnboardingDate: string,
  integration: {
    externalId: string;
    service: IntegrationServices,
  }
}

export interface ICreateNursingHomeRequest {
  name: string,
  address: string,
  phone: string,
  email: string,
  organizationId: string,
}

export interface IEditNursingHomeRequest extends Omit<IGetNursingHomeResponse, '_id'> {
}
export interface IEditNursingHomePhotoRequest {
  imageFileType: string,
}
export interface ICreateNursingHomeResponse extends IGetNursingHomeResponse {

}

export interface IEditNursingHomeResponse extends IGetNursingHomeResponse {
  action?: string,
}

export enum IntegrationServices {
  E_CASE = 'ECase',
  LEE_CARE = 'LeeCare',
  AZURE_AD = 'azure_ad',
  TELSTRA_HEALTH = 'telstra_health',
}

export interface INursingHome extends IGetNursingHomeResponse {
  name: string,
  address: string,
  _id: string,
  phone: string,
  email: string,
  photo: string,
  accessKeys: string[],
  organization: {
    description: string,
    name: string,
    owner: {
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
    },
    _id: string,
  },
  isEmployeeUploaded: boolean,
  isFamiliesUploaded: boolean,
  status: HomeStatus,
  lastEmployeeInvitation: string,
  lastFamiliesInvitation: string,
  endOnboardingDate: string,
  integration: {
    externalId: string,
    service: IntegrationServices,
  }
}

export enum HomeStatus {
  READY_FOR_ONBOARDING = 'ready_for_onboarding',
  ONBOARDING = 'onboarding',
  IN_USE = 'in_use',
}

export const HomeStatusName = {
  [HomeStatus.ONBOARDING]: 'Onboarding in process',
}

export enum UserOnboardingStatus {
  ONBOARDING = 'onboarding',
  IN_USE = 'in_use',
}
export interface IGetNursingHomesResponse {
  nursingHomes: INursingHome[],
  totalCount: number,
}

export interface IGetNursingHomesRequest {
  searchString: string, 
  organizationId: string, 
  limit: number,
  page: number,
}

export interface IGetAvailableNursingHomesRequest {
  organizationId: string, 
}

export interface IGetAvailableNursingHomesResponse extends INursingHome {
}

export interface IGetOrganizationsReq {
  limit: number,
  page: number,
  searchString: string,
}

export interface IGetOrganizationsResponse {
  totalCount: number,
  organizations: IOrganization[],
}

export interface IGetNewResidentsReq {
  homeId: string;
}

export interface IGetNewResidentsRes {
  residents: IResident[];
}

export interface IOrganization {
  _id: string,
  name: string,
  description: string
  integration: {
    service: string;
    externalId: string;
    sessionId: string;
    rootLink: string;
  }
}

export interface HeadCellOrganizations {
  disablePadding: boolean;
  id: keyof IOrganization;
  label: string;
}

export const SELECTED_NURSING_HOME_ID = 'selected_nursing_home_id';
export const SELECTED_ORGANIZATION_ID = 'selected_organization_id';