import { observer } from "mobx-react-lite";
import styles from "@pages/LoadingPage/loadingPage.module.scss";
import Loader from "@components/UI/loader/loader";
import loadingImg from "@assets/imgs/logoFull.svg";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useAuth} from "@source/hooks/useAuth";
import useFetching from "@source/hooks/useFetching";
import UserController from "@source/api/controllers/userController";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import {useEffect, useState} from "react";
import userStore from "@store/userStore";
import {PrivateRoutes} from "@source/AppRouter";
import {Role} from "@source/utils/models/adminModels";
import {HOME_ROUTES, SUPER_ADMIN_PANEL_ROUTES} from "@source/utils/utils";
import {IGetAvailableNursingHomesRequest} from "@source/api/models/nursingHomeModels";
import nursingHomeStore from "@store/nursingHomeStore";
import {api} from "@source/api/http";
import {LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE} from "@source/utils/models/userModels";
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import AuthController from "@source/api/controllers/authController";
import Notification from "@components/snackMessage/notification";
import InteractionObserver from "@source/api/interactionObserver";

const AzureADLoginPage = observer((props: LoginPageProps) => {
    const router = useHistory();
    const authed = useAuth();
    const { organization: organizationName } = useParams<{organization: string}>()

    const [azureLogin] = useFetching<ReturnType<typeof AuthController.loginAzureAD>>(AuthController.loginAzureAD);
    const [profile, isLoading, error] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
    const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
    const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
    const [getOrganization, getOrganizationIsLoading, getOrganizationError] = useFetching<ReturnType<typeof NursingHomeController.getOrganizationByName>>(NursingHomeController.getOrganizationByName);
    const location = useLocation();
    const [isNeedLoad, setIsNeedLoad] = useState(!userStore.getId() && !!PrivateRoutes.find((route) => location.pathname.includes(route.path)) && localStorage.getItem('refreshToken'));

    useEffect(() => {
        localStorage.setItem('preferredLoginPage', location.pathname)
        const getProfile = async () => {
            const profileInfo = await profile();

            if(profileInfo) {
                userStore.setUserInfo(profileInfo);

                if (profileInfo?.account.role === Role.SUPER_ADMIN) {
                    if (userStore.getLocalStorageOrganizationId()) {
                        userStore.setOrganizationId(userStore.getLocalStorageOrganizationId());
                    } else {
                        router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
                    }
                } else {
                    userStore.setOrganizationId(profileInfo.account.organization._id);
                }
            }

            if (userStore.getLocalStorageOrganizationId() || profileInfo?.account.role !== Role.SUPER_ADMIN) {
                const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
                    organizationId: userStore.getOrganizationId(),
                }

                const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);

                if (availableNursingHomesRes) {
                    nursingHomeStore.setNursingHomes({
                        nursingHomes: availableNursingHomesRes,
                        totalCount: availableNursingHomesRes.length
                    });

                    const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
                    nursingHomeStore.setActiveNursingHome(activeNursingHome);

                    if (nursingHomeStore.activeNursingHome) {
                        api.defaults.params = {
                            ...api.defaults.params,
                            nursingHomeId: nursingHomeStore.activeNursingHome._id,
                        }
                    }
                }


                await setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
            }

            setIsNeedLoad(false)
        }
        const initMsal = async () => {
            const org = await getOrganization(organizationName);
            if(!org) {
                return;
            }
            const msalApp = new PublicClientApplication({
                auth: {
                    clientId: org?.integration?.sessionId,
                    redirectUri: process.env.REACT_APP_API_URL_SHORT + `/`,
                    authority: org?.integration?.rootLink,
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true
                }
            })
            msalApp.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const authenticationResult = event.payload as AuthenticationResult;
                    const account = authenticationResult.account;
                    msalApp.setActiveAccount(account);
                }
            });
            msalApp.enableAccountStorageEvents();
            await msalApp.initialize();
            return { msalApp, organization: org };
        }

        const login = async () => {
            const msalData = await initMsal()
            let msalApp, organization;
            if (!msalData) {
                return;
            }
            msalApp = msalData.msalApp;
            organization = msalData.organization
            await msalApp?.handleRedirectPromise();
            const resp = await msalApp?.loginPopup({ scopes: [`${organization.integration.sessionId}/.default`], prompt: 'select_account'})
            localStorage.setItem("accessToken", resp.accessToken);
            const loginData = { uniqueId: resp.uniqueId, organizationId: organization._id}
            const loginResponse = await azureLogin(loginData);
            if(loginResponse) {
                    localStorage.setItem("refreshToken", loginResponse.refreshToken);
                    localStorage.setItem("accessToken", loginResponse.accessToken);

                    await getProfile();
                    new Notification().success('You’re successfully logged in');
                    const isFromSetPasswordPage = localStorage.getItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE);

                    if (userStore.getUserInfo()?.account.role === Role.SUPER_ADMIN) {
                        router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
                    } else {
                        if (isFromSetPasswordPage) {
                            router.push(isFromSetPasswordPage)
                            localStorage.removeItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE);
                        } else {
                            router.push(HOME_ROUTES.Residents);
                        }
                    }

                    new InteractionObserver();
                    userStore.resetLoginEmail();
            }
        }

        initMsal()
        if(isNeedLoad) {
            getProfile();
        } else {
            login()
        }
    }, [])

    return (
        <div className={styles.loading}>

            <img className={styles.loading_img} src={loadingImg} alt="loading"/>
            <div className={styles.loading__top}>
                <Loader className={styles.loading__top_loader} color="#3956C7" height={40} width={40}/>
                <div className={styles.loading__top_text}>Please wait</div>
            </div>
        </div>
    )
})

export default AzureADLoginPage;

interface LoginPageProps {

}